import React, { useRef } from "react"
import { useTranslation } from "react-i18next"

import ListSection from "../components/ListSection"
import SEO from "../components/seo"
import { useSkewOnScroll } from "../hooks/scroll"
import { sortByName } from "../utils/text"

const DIRECTORS = [
  {
    label: "Cristele ALVES Meira",
    url: "https://www.imdb.com/name/nm7114437/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Jean-Philippe Amar",
    url: "https://www.imdb.com/name/nm1267565/?ref_=nv_sr_srsg_4",
  },
  {
    label: "Nabil Ayouch",
    url: "https://www.imdb.com/name/nm0043953/?ref_=fn_al_nm_1",
  },
  {
    label: "Luc Battiston",
    url: "https://www.imdb.com/name/nm1578723/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Keren BEN Rafael",
    url: "https://www.imdb.com/name/nm1180774/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Ramzi BEN Sliman",
    url: "https://www.imdb.com/name/nm2806105/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Hugo Benamozig",
    url: "https://www.imdb.com/name/nm5339583/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Antoine Besse",
    url: "https://www.imdb.com/name/nm5675643/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Samuel Bodin",
    url: "https://www.imdb.com/name/nm3306595/?ref_=nv_sr_srsg_3",
  },
  {
    label: "Pascal Bourdiaux",
    url: "https://www.imdb.com/name/nm0099797/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Guillaume Brac",
    url: "https://www.imdb.com/name/nm2145328/?ref_=nv_sr_srsg_0",
  },
  { label: "David Caviglioli", url: "https://www.imdb.com/name/nm10709088/" },
  {
    label: "Hélier Cisterne",
    url: "https://www.imdb.com/name/nm1345862/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Baptiste Debraux",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/553823-baptiste-debraux.html",
  },
  {
    label: "Mati Diop",
    url: "https://www.imdb.com/name/nm2841936/?ref_=fn_al_nm_1",
  },
  {
    label: "Julia Ducournau",
    url: "https://www.imdb.com/name/nm4469445/?ref_=fn_al_nm_1",
  },
  {
    label: "Ismael EL Iraki",
    url: "https://www.imdb.com/name/nm3271855/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Mareike Engelhardt",
    url: "https://www.imdb.com/name/nm4496825/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Pascale Ferran",
    url: "https://www.imdb.com/name/nm0273863/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Yann Gonzalez",
    url: "https://www.imdb.com/name/nm2287606/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Alain Guiraudie",
    url: "https://www.imdb.com/name/nm0347492/?ref_=fn_al_nm_1",
  },
  {
    label: "Ted HARDY-Carnac",
    url: "https://www.imdb.com/name/nm5429518/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Hu Wei",
    url: "https://www.imdb.com/name/nm5130656/?ref_=fn_al_nm_1",
  },
  {
    label: "Florence Hugues",
    url: "https://www.imdb.com/name/nm9935683/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Héléna Klotz",
    url: "https://www.imdb.com/name/nm1514932/?ref_=nv_sr_srsg_1",
  },
  {
    label: "William Laboury",
    url: "https://www.imdb.com/name/nm5189456/?ref_=fn_al_nm_1",
  },
  {
    label: "Alexis Langlois",
    url: "https://www.imdb.com/name/nm5652096/?ref_=fn_al_nm_1",
  },
  {
    label: "Kamal Lazraq",
    url: "https://www.imdb.com/name/nm4474865/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Eliza Levy",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/636691-eliza-levy.html",
  },
  {
    label: "Lila Pinell",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/728332-lila-pinell.html",
  },
  {
    label: "JB Marlin",
    url: "https://www.imdb.com/name/nm3014863/?ref_=nv_sr_srsg_3",
  },
  {
    label: "Emmanuel Marre",
    url: "https://www.imdb.com/name/nm4345767/?ref_=fn_al_nm_1",
  },
  {
    label: "Laurent Micheli",
    url: "https://www.imdb.com/name/nm4714963/?ref_=fn_al_nm_1",
  },
  {
    label: "François Ozon",
    url: "https://www.imdb.com/name/nm0654830/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Benjamin Parent",
    url: "https://www.imdb.com/name/nm4874812/?ref_=fn_al_nm_3",
  },
  {
    label: "Claire Patronik",
    url: "https://www.imdb.com/name/nm4938888/?ref_=fn_nm_nm_3",
  },
  {
    label: "Aurélien Peyre",
    url: "https://www.imdb.com/name/nm7787010/?ref_=fn_al_nm_1",
  },
  {
    label: "Nicolas Lopez",
    url: "https://www.imdb.com/name/nm6951409/?ref_=ttfc_fc_dr2",
  },
  {
    label: "Caroline Poggi",
    url: "https://www.imdb.com/name/nm5687471/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Alexe Poukine",
    url: "https://www.imdb.com/name/nm6209132/?ref_=fn_al_nm_1",
  },
  {
    label: "Tiphaine Raffier",
    url: "https://www.imdb.com/name/nm8991306/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Régis Roinsard",
    url: "https://www.imdb.com/name/nm1672051/?ref_=fn_al_nm_1",
  },
  {
    label: "Victor SAINT Macary",
    url: "https://www.imdb.com/name/nm6872947/?ref_=nv_sr_srsg_8",
  },
  {
    label: "Guillaume Senez",
    url: "https://www.imdb.com/name/nm1879267/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Leonor Serraille",
    url: "https://www.imdb.com/name/nm4874872/?ref_=nv_sr_srsg_0",
  },

  {
    label: "Tommaso Usberti",
    url: "https://www.imdb.com/name/nm8266757/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Nicolas Vanier",
    url: "https://www.imdb.com/name/nm1534640/?ref_=fn_al_nm_1",
  },
  {
    label: "Jonathan Vinel",
    url: "https://www.imdb.com/name/nm5687538/?ref_=fn_al_nm_1",
  },
  {
    label: "Thomas Bidegain",
    url: "https://www.imdb.com/name/nm0081179/?ref_=fn_al_nm_1",
  },
  {
    label: "Nyima Cartier",
    url: "https://www.imdb.com/name/nm3227744/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Rémy SILK Binisti",
    url: "https://www.imdb.com/name/nm2337673/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Julien Despaux",
    url: "https://www.imdb.com/name/nm1927069/?ref_=fn_al_nm_1",
  },
  {
    label: "Léa Fredeval",
    url: "https://www.imdb.com/name/nm9113892/?ref_=fn_al_nm_1",
  },
  {
    label: "Bertrand Bonello",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/552668-bertrand-bonello.html",
  },
  {
    label: "Paul Marques Duarte",
    url: "https://www.imdb.com/name/nm5851450/",
  },
  {
    label: "Joris Faucon Grimaud",
    url: "https://www.imdb.com/name/nm8025685/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Paolo Barzman",
    url: "https://www.imdb.com/name/nm0059556/?ref_=fn_al_nm_1",
  },
  {
    label: "Pierre Le Gall",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/750223-pierre-le-gall.html",
  },
  {
    label: "Maxime Chamoux",
    url: "https://www.imdb.com/name/nm4703937/?ref_=fn_al_nm_1",
  },
  {
    label: "Sylvain Gouverneur",
    url: "https://www.imdb.com/name/nm7233869/?ref_=ttfc_fc_dr2",
  },
  {
    label: "Adriano Valerio",
    url: "https://www.imdb.com/name/nm2384515/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Léna Mardi",
    url: "https://www.unifrance.org/annuaires/personne/448281/lena-mardi",
  },
  {
    label: "Quentin Nozet",
    url: "https://www.imdb.com/name/nm13399398/?ref_=fn_al_nm_1",
  },
  {
    label: "Eduardo Carretié",
    url: "https://www.imdb.com/name/nm8059494/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Catherine Cosme",
    url: "https://www.imdb.com/name/nm2844881/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Luccio Di Rosa",
    url: "https://www.imdb.com/name/nm1498915/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Florent Gouëlou",
    url: "https://www.imdb.com/name/nm3423115/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Faustine Crespy",
    url: "https://www.imdb.com/name/nm7347037/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Wilmarc Val",
    url: "https://www.imdb.com/name/nm7194615/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Camille VIDAL-Naquet",
    url: "https://www.imdb.com/name/nm1283911/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Walid Mattar",
    url: "https://www.imdb.com/name/nm4438253/?ref_=fn_al_nm_1",
  },
  {
    label: "Maryam Touzani",
    url: "https://www.imdb.com/name/nm5685112/?ref_=fn_al_nm_1",
  },
  {
    label: "Julien Mokrani",
    url: "https://www.imdb.com/name/nm2056868/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Homeïda Behi",
    url: "https://www.imdb.com/name/nm5100619/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Eva Husson",
    url: "https://fr.wikipedia.org/wiki/Eva_Husson",
  },
  {
    label: "Pauline Loques",
    url: "https://www.unifrance.org/annuaires/personne/457459/pauline-loques",
  },
  {
    label: "Amartei Armar",
    url:
      "https://www.unifrance.org/annuaires/personne/463045/amartei-armar?logged=1675193250441",
  },
  {
    label: "Yohann Kouam",
    url: "https://www.unifrance.org/annuaires/personne/349575/yohann-kouam",
  },
  {
    label: "Paul Rigoux",
    url: "https://www.imdb.com/name/nm12584072/?ref_=fn_al_nm_1",
  },
  {
    label: "Clémentine Carrié",
    url:
      "https://www.unifrance.org/annuaires/personne/436502/clementine-carrie",
  },
  {
    label: "Anne Feinsilber",
    url: "https://fr.wikipedia.org/wiki/Anne_Feinsilber",
  },
  {
    label: "Edie Blanchard",
    url: "https://www.imdb.com/name/nm1867037/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Johanna Makabi",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/758170-johanna-makabi.html",
  },
  {
    label: "Anthony Lapia",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/762431-anthony-lapia.html",
  },
  {
    label: "Aude N'guessan Forget",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/762432-aude-n-guessan-forget.html",
  },
  {
    label: "Claire Doyon",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/762434-claire-doyon.html",
  },
  {
    label: "Marguerite Pellerin",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/762444-marguerite-pellerin.html",
  },
  {
    label: "Zoel Aeschbacher",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/762439-zoel-aeschbacher.html",
  },
  {
    label: "Lara Panah-Izadi",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/762441-lara-panah-izadi.html",
  },
  {
    label: "Sabrina idiri Chemloul",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/762443-sabrina-chemloul.html",
  },
  {
    label: "Alexane Andrieu",
    url:
      "https://www.imdb.com/name/nm11974029/?ref_=nv_sr_srsg_0_tt_0_nm_8_q_alexane%2520andri",
  },
  {
    label: "Maxence Vassilyevitch",
    url:
      "https://www.imdb.com/name/nm5019576/?ref_=nv_sr_srsg_0_tt_0_nm_8_q_maxence%2520vass",
  },
  {
    label: "Nora Mandray",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/758728-nora-mandray.html",
  },
  {
    label: "Romain DUMONT",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/765510-romain-dumont.html",
  },
  {
    label: "Faouzi BENSAÏDI",
    url:
      "https://www.imdb.com/name/nm0072364/?ref_=nv_sr_srsg_0_tt_0_nm_4_q_faouzi%2520bensai",
  },
  {
    label: "Olivia NKOMI",
    url:
      "https://www.imdb.com/name/nm6014386/?ref_=nv_sr_srsg_0_tt_2_nm_6_q_olivia%2520nkomi",
  },
  {
    label: "Benoît MARTIN",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/714602-benoit-martin.html",
  },
  {
    label: "Juliette Roudet",
    url:
      "https://www.imdb.com/name/nm1675913/?ref_=nv_sr_srsg_0_tt_1_nm_7_q_Juliette%2520Roudet",
  },
  {
    label: "Khalil Joreige",
    url: "https://fr.wikipedia.org/wiki/Joana_Hadjithomas_et_Khalil_Joreige",
  },
  {
    label: "Joana Hadjithomas",
    url: "https://fr.wikipedia.org/wiki/Joana_Hadjithomas_et_Khalil_Joreige",
  },
  {
    label: "David Depesseville",
    url:
      "https://www.imdb.com/name/nm2293538/?ref_=nv_sr_srsg_0_tt_0_nm_2_q_David%2520Depesville",
  },
  {
    label: "Céline Gailleurd",
    url:
      "https://www.imdb.com/name/nm6487215/?ref_=nv_sr_srsg_0_tt_2_nm_4_q_C%25C3%25A9line%2520Gailleur",
  },
  {
    label: "Gala Hernandez Lopez",
    url:
      "https://www.imdb.com/name/nm15337809/?ref_=nv_sr_srsg_0_tt_0_nm_8_q_Gala%2520Hernandez-Lopez",
  },
  {
    label: "Olivier Bohler",
    url:
      "https://www.imdb.com/name/nm3166309/?ref_=nv_sr_srsg_0_tt_0_nm_8_q_Olivier%2520Bohler",
  },
  {
    label: "Marguerite Thiam",
    url:
      "https://www.imdb.com/name/nm10435313/?ref_=nv_sr_srsg_0_tt_4_nm_4_q_Marguerite%2520Thiam",
  },
  {
    label: "Mathis Raymond",
    url:
      "https://www.imdb.com/name/nm13721249/?ref_=nv_sr_srsg_0_tt_0_nm_8_q_Mathis%2520Raymond",
  },
  {
    label: "Paloma Sermon-Daï",
    url: "https://fr.wikipedia.org/wiki/Paloma_Sermon-Da%C3%AF",
  },
  {
    label: "Cédric Kahn",
    url: "https://fr.wikipedia.org/wiki/C%C3%A9dric_Kahn",
  },
  {
    label: "Yolande Zauberman",
    url: "https://fr.wikipedia.org/wiki/Yolande_Zauberman",
  },
  { label: "Leos Carax", url: "https://fr.wikipedia.org/wiki/Leos_Carax" },
  {
    label: "Moritz Parisius",
    url: "https://pro.imdb.com/name/nm4439277/overview",
  },
  {
    label: "Nicolas Martin",
    url:
      "https://www.imdb.com/name/nm11721995/?ref_=nv_sr_srsg_9_tt_0_nm_8_q_Nicolas%2520Martin",
  },
].sort(sortByName)

const Directors = (): JSX.Element => {
  const containerRef = useRef(null)
  const { t } = useTranslation()

  useSkewOnScroll(containerRef)
  return (
    <>
      <SEO title={t("directors")} />
      <div ref={containerRef} style={{ willChange: "transform" }}>
        <ListSection list={DIRECTORS} id="directors" key="directors" />
      </div>
    </>
  )
}

export default Directors
